import React from "react"
// Components
import { StillQuestions, Layout } from "../components"
// Styles
import "../styles/pricePage.sass"

const PricePage = () => {
  return (
    <Layout
      title='Цены на услуги | Студия красоты "Lab Beauty" Ирины Лучистой'
      description="Стоимость татуажа, маникюр, педикюра, удаление тату в г. Бровары"
      keywords="Цена татуажа, стоимость татуажа бровары, маникюр, педикюр, цены в салоне красоты">
      <section className="price-page">
        <h1>Наши цены</h1>

        <div className="price-list">
          <div className="left">
            <h2>Перманентный макияж (татуаж):</h2>
            <ul>
              <li>Акварельные губы - 2500 грн</li>
              <li>Пудровое напыление бровей - 2500 грн</li>
              <li>Межресничная стрелка - 2500 грн</li>
            </ul>

            <h2>Удаление тату/татуажа:</h2>
            <ul>
              <li>Лазерное удаление - 500 грн</li>
              <li>Удаление ремувером - 800 - 1000 грн</li>
            </ul>

            <h2>Услуги по уходу за руками:</h2>
            <ul>
              <li>Маникюр гигиенический женский (обрезной) - 150 грн</li>
              <li>Маникюр гигиенический мужской (обрезной) - 180 грн</li>
              <li>Маникюр + гель-лак - 330 грн</li>
              <li>Укрепление ногтей базой (выравнивание) - 50 грн</li>
              <li>
                Покрытие ногтей (основа, лак, закреп., сушка, масло) - 70 грн
              </li>
              <li>Покрытие ногтей гель-лак - 180 грн</li>
              <li>Парафинотерапия - 80 грн</li>
              <li>Наращивание ногтей гелем - 500 грн</li>
              <li>Коррекция ногтей гелем - 380 грн</li>
            </ul>

            <h2>Услуги по ухода за ногами:</h2>
            <ul>
              <li>Педикюр медицинский женский - 350-400 грн</li>
              <li>Педикюр медицинский мужской - 400-450 грн</li>
              <li>Покрытие ногтей гель-лак - 150 грн</li>
              <li>Покрытие ногтей гель-лак - 500-550 грн</li>
              <li>
                Покрытие ногтей (основа, лак, закреп., сушка, масло) - 70 грн
              </li>
              <li>Педикюр "light" (пальчики) - 150 грн</li>
              <li>Педикюр "light" (пальчики) с покрытием гель-лак - 300 грн</li>
              <li>Парафинотерапция - 100 грн</li>
            </ul>
          </div>

          <div className="right">
            <h2>Оформление бровей и ресниц:</h2>
            <ul>
              <li>Форма бровей - 70 грн</li>
              <li>Коррекция бровей - 50 грн</li>
              <li>Окрашивание бровей - 150 грн</li>
              <li>Окрашивание ресниц - 100 грн</li>
              <li>Ламинирование ресниц (ботокс в подарок) - 500 грн</li>
              <li>Завивка ресниц (ботокс в подарок) - 450 грн</li>
            </ul>

            <h2>Аппаратная косметология:</h2>
            <ul>
              <li>Лазерный карбоновый пилинг - 600 грн</li>
              <li>Микротоковая терапия - 450 грн</li>
              <li>Фракционная мезотерапия - от 450 грн</li>
            </ul>

            <h2>Восковая депиляция:</h2>
            <ul>
              <li>Руки (до локтя) - 150 грн</li>
              <li>Руки 100% - 180 грн</li>
              <li>Ноги (голень) - 160 грн</li>
              <li>Ноги (бедра) - 180 грн</li>
              <li>Ноги 100% - 210 грн</li>
              <li>Лицо (над губой) - 100 грн</li>
              <li>Подмышечная область - 120 грн</li>
            </ul>
          </div>
        </div>
      </section>
      <StillQuestions />
    </Layout>
  )
}

export default PricePage
